@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background-color: #060606;
	font-family: 'Kanit', sans-serif;
}

/** Colors **/

$black: #000;
$white: #fff;

$font-kanit : 'Kanit',
sans-serif;


/** Common Styles **/


@keyframes rainbow {
	0% {
		background-position: right
	}

	50% {
		background-position: left
	}

	100% {
		background-position: right
	}
}


@mixin df-ac {
	display: flex;
	align-items: center;
}

@mixin df-ac-jc {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin df-ac-jsb {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin dg-ac {
	display: grid;
	align-items: center;
}

@mixin dg-ac-jc {
	display: grid;
	align-items: center;
	justify-content: center;
}

@mixin dg-ac-jsb {
	display: grid;
	align-items: center;
	justify-content: space-between;
}

.transition {
	transition: .5s all;
}

.br-50px {
	border-radius: 50px;
}

.br-50per {
	border-radius: 50%;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

a,
a:hover {
	text-decoration: none;
}

.btn:focus,
.form-control:focus {
	box-shadow: none;
}

.btn,
.form-control {
	outline: 0;
}

.linear-txt {
	background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-size: 50% 50%;
	animation: rainbow 5s linear infinite;
}

.btn-primary {
	background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
	backdrop-filter: blur(4.5px);
	border-radius: 50px;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	color: $white;
	padding: 0;
	outline: 0;
	border: 0;
	@extend .transition;
	position: relative;
	border: 0;

	&:not(:disabled):not(.disabled):active:focus {
		box-shadow: none;
	}

	&:not(:disabled):not(.disabled):active,
	&:hover,
	&:active,
	&:focus {
		background: linear-gradient(90deg, #FF8C21 13.4%, #E6F294 86.6%);
		color: #060606;
		box-shadow: none;
	}

}

@media only screen and (min-width: 1400px) {

	.container {
		width: 100%;
		max-width: 1175px;
	}

}

/** Scrollbar Css Styles **/
.cus-scroll {
	scrollbar-width: thin;
	scrollbar-color: #FF9D35 #000;
	overflow-y: auto;
}

.cus-scroll::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

.cus-scroll::-webkit-scrollbar-track {
	background-color: #000;
}

.cus-scroll::-webkit-scrollbar-thumb {
	background-color: #FF9D35;
}

.cus-scroll::-webkit-scrollbar-track,
.cus-scroll::-webkit-scrollbar-thumb {
	border-radius: 50px;
}

@-moz-document url-prefix() {
	.cus-scroll {
		scrollbar-width: thin;
		scrollbar-color: #FF9D35 #000;
		overflow-y: auto;
	}

	.cus-scroll::scrollbar {
		width: 4px;
		height: 4px;
	}

	.cus-scroll::-webkit-scrollbar-track {
		background-color: #000;
	}

	.cus-scroll::-webkit-scrollbar-thumb {
		background-color: #FF9D35;
	}

	.cus-scroll::-scrollbar-track,
	.cus-scroll::-webkit-scrollbar-thumb {
		border-radius: 50px;
	}
}

/** Scrollbar Css Styles **/


/** SideMenu Css START **/

.modal-open {

	header,
	.homemain-cnt {
		filter: blur(5px);
	}
}


.modal.right.fade .modal-dialog {
	right: -320px;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
	right: 0;

	.modal-content {
		background: transparent;
	}
}

.sidemenu-popup {

	.modal-dialog {
		margin: 0 0 0 auto;
	}

	.sidemenu-main {
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(14px);
		// width: 344px;
		height: 100vh;
		padding: 20px 20px 15px 20px;
		margin: 0 0 0 auto;
		pointer-events: auto;

		// background-color: #E3BE53;


		.sidemenu-inrcnt {
			height: calc(100vh - 35px);
			overflow-y: auto;

			ul {
				max-width: 185px;

				li {
					// margin: 0 0 5px 0;


					a {
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						color: $white;
						padding: 10px 0 10px 10px;
						position: relative;
						@extend .transition;
						// border-bottom: 1px solid rgba(255, 255, 255, 0.1);
						border-bottom: 1px solid rgba(255, 255, 255, 0.1);
						width: 100%;
						display: inline-block;
						z-index: 9999;

						&::before,
						&::after {
							content: '';
							position: absolute;
							width: 0;
							@extend .transition;
							left: 0;

						}

						&::after {

							height: 1px;
							background-color: #EAB144;
							// background: linear-gradient(140.95deg, #F4C247 28.07%, #EAB144 52%, #D1843D 98.81%, #BD6137 132.1%);
							border-radius: 5px;
							bottom: -1px;
						}

						&::before {
							bottom: 0;
							height: 100%;
							background: rgba(217, 217, 217, 0.1);
							// border-bottom: 1px solid rgba(255, 255, 255, 0.1);
						}

						&:hover {
							color: #F4C247;

							&::before,
							&::after {
								width: 100%;
							}


						}


					}
				}
			}
		}


	}
}

/** SideMenu Css END **/



/** Header Css START **/


header {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 999;

	.header-main {
		.header-inrcnt {
			padding: 25px 20px 0 20px;

			.header-subcnt {
				@include df-ac-jsb();

			}

		}
	}

}

/** Header Css END **/


/** Landing Page Css START **/

.home-maincnt {
	min-height: 100vh;

	section {
		width: 100%;
		min-height: 100vh;
		display: grid;
		align-items: center;
		position: relative;


		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('/assets/images/homebg-new.png');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}

		.homemain-inrcnt {
			padding: 30px 25px;

			.homemain-leftcnt {
				h1 {
					@extend .linear-txt;
					font-style: normal;
					font-weight: 600;
					font-size: 80px;
					line-height: 91px;
				}

				button {
					min-width: 212px;
					min-height: 55px;
					margin: 30px 0 0 0;
					@include df-ac-jc();

					span {
						margin: 0 0 0 10px;
					}

				}


			}

			.homemain-rightcnt {


				.homemain-rightinrcnt {
					text-align: center;

					.video-layer1,
					.video-layer2 {
						background: linear-gradient(to right, #060606, #060606) padding-box,
							linear-gradient(180deg, #E3BE53 29.7%, #6B3802 53.62%, ) border-box;
						border-radius: 28px;
						border: 2px solid transparent;
						@extend .transition;
					}

				}

			}
		}
	}
}


/** Landing Page Css END **/



/** Home Page Css START **/


.homemain-cnt {
	min-height: calc(100vh - 130px);
	position: relative;
	padding: 130px 0 0 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('/assets/images/home-bg1.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.homepage-inrcnt {
		position: relative;
		min-height: calc(100vh - 318px);
		padding: 50px 15px;

		h1 {
			@extend .linear-txt;
			font-style: normal;
			font-weight: 600;
			// font-size: 67px;
			// line-height: 100px;
			text-align: center;
		}

		h5 {
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 36px;
			color: $white;
			text-align: center;
		}

		.playbox-cnt {
			text-align: center;
			margin: 45px 0 0 0;

			.play-gridmain {



				.playmain-box {
					background: rgba(255, 255, 255, 0.07);
					// background: linear-gradient(to right, #000, #000) padding-box, linear-gradient(135deg, #BB8E27 4%, #000 20%, #000 80%, #AF821B 100%) border-box;
					backdrop-filter: blur(4.5px);
					border-radius: 15px;
					min-height: 146px;
					@include df-ac-jc();
					text-align: center;
					@extend .transition;
					position: relative;


					&::before {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background-image: url('/assets/images/border.png');
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 100% 100%;
						@extend .transition;

					}


					&:hover {
						backdrop-filter: blur(0);
						transform: skew(-3deg, -3deg);
					}

					h5 {
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 27px;
						color: #FFFFFF;
						margin: 12px 0 0 0;
					}

				}
			}
		}


	}

}

/** Home Page Css END **/



/** Footer Css START **/

.home-footer {
	position: relative;
	padding: 25px 15px;

	.ftr-social {
		ul {

			li {
				width: 38px;
				height: 38px;
				display: inline-block;

				&.pandali {
					a {
						&:hover {
							svg {

								path {
									fill: transparent;
									stroke: #000;
								}


							}
						}
					}
				}

				a {
					border: 0.5px solid #F2AA34;
					border-radius: 5px;
					@include df-ac-jc();
					width: 100%;
					height: 100%;
					@extend .transition;

					svg {
						@extend .transition;
					}

					&:hover {
						background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
						box-shadow: 0 0 20px 2px #8d3012;

						svg {
							transform: scale(1.1);

							path {
								fill: $black;
							}
						}
					}

				}

			}
		}
	}

	.copy-txt {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		color: $white;
		text-align: center;
		margin: 15px 0 0 0;
	}

	.language-select {
		text-align: center;
		margin: 20px 0 0 0;

		.language-grid {
			@include dg-ac();
			display: inline-grid;
			grid-template-columns: 28px auto;

			background: rgba(255, 255, 255, 0.07);
			backdrop-filter: blur(6.23077px);
			border-radius: 20.7692px;
			padding: 8px;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('/assets/images/lanborder.png');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% 100%;
				z-index: -1;
			}

			select {
				appearance: none;
				background-color: transparent;
				padding: 0 30px 0 0;
				border: 0;
				outline: 0;
				margin: 0 0 0 15px;

				background-image: url('/assets/images/drop-arr.png');
				background-position: center right 10px;
				background-repeat: no-repeat;
				background-size: auto;

				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 27px;
				color: $white;

				option {
					background-color: #060606;
				}


			}


		}
	}

}

/** Footer Css END **/


/** Media Queries START **/

@media only screen and (min-width: 481px) {
	footer {
		.ftr-social {
			ul {
				@include dg-ac-jc();
				grid-template-columns: auto auto auto auto auto auto auto auto;

				li {
					margin: 0 12px 0 0;
				}
			}
		}
	}
}

@media only screen and (min-width: 400px) and (max-width: 480px) {

	.homemain-cnt .homepage-inrcnt h1,
	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 50px;
		line-height: 60px;
	}
}

@media only screen and (min-width: 481px) and (max-width: 575px) {

	.homemain-cnt .homepage-inrcnt h1,
	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 60px;
		line-height: 70px;
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

	.homemain-cnt .homepage-inrcnt h1,
	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 70px;
		line-height: 85px;
	}
}


@media only screen and (min-width: 576px) {

	.sidemenu-main {
		width: 344px;
	}

}

@media only screen and (min-width: 768px) {

	.play-gridmain {
		@include dg-ac();
		grid-template-columns: 195px 195px 195px;
		justify-content: space-between;
		max-width: 650px;
		margin: 0 auto;
	}


	.homemain-rightcnt {
		text-align: center;

		.homemain-rightinrcnt {

			position: relative;
			display: inline-block;

			.video-layer1,
			.video-layer2 {
				min-width: 544px;
				min-height: 341px;
				display: inline-block;
			}

			.video-layer2 {
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(-35px, 35px);
			}

			.videobox-main {


				position: absolute;
				top: 0;
				left: 0;
				transform: translate(-70px, 85px);
				max-width: 548px;
				min-height: 308px;
				border-radius: 40px;


				img {
					border-radius: 40px;
				}

			}


		}

	}

	.homemain-cnt .homepage-inrcnt h1 {
		font-size: 67px;
		line-height: 100px;
	}


}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1400px) {}


@media only screen and (max-width: 1199px) {

	.home-maincnt section .homemain-inrcnt .homemain-leftcnt button {
		margin-left: auto;
		margin-right: auto;
	}

	.homemain-leftcnt {
		text-align: center;
	}

	.homemain-rightcnt {
		margin: 80px 0 0 0;
	}
}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {

	.play-gridmain {
		padding: 0 15px;

		.playmain-box {
			max-width: 200px;
			margin: 0 auto;

			&:nth-child(2) {
				margin: 20px auto 20px auto;
			}
		}

	}


	.homemain-rightinrcnt {
		.videobox-main {
			img {
				border-radius: 20px;
			}
		}

		.video-layer1,
		.video-layer2 {
			display: none;
		}
	}

}

@media only screen and (max-width: 575px) {

	.sidemenu-main {
		width: 70%;
	}
}

@media only screen and (max-width: 480px) {

	footer {
		.ftr-social {
			ul {
				@include dg-ac();
				grid-template-columns: auto auto auto auto;
				justify-content: center;

				li {
					margin: 0 10px 10px 0;
				}

			}
		}
	}

}

@media only screen and (max-width: 399px) {

	.homemain-cnt .homepage-inrcnt h1,
	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 45px;
		line-height: 50px;
	}
}





/** 17-05-2023 **/

.homemain-leftcnt {
	button {
		svg {
			@extend .transition;
		}

		&:hover {
			svg {
				path {
					fill: #060606;
				}
			}
		}
	}
}

@media only screen and (min-width: 1400px) {

	.homemain-inrcnt {
		width: 100%;
		max-width: 90%;
		margin: 0 auto;
	}

	.homemain-rightcnt {
		text-align: right;
	}


}

@media only screen and (min-width: 1800px) {

	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 100px;
		line-height: 120px;
	}

}

@media only screen and (min-width: 2000px) {

	.home-maincnt section .homemain-inrcnt .homemain-leftcnt h1 {
		font-size: 120px;
		line-height: 130px;
	}

}

@media only screen and (max-width: 767px) {

	.homemain-rightinrcnt {
		&:hover {
			.videobox-main {
				transform: unset !important;
			}
		}
	}
}





/** 31-05-2023 **/

.inrpage-footer {
	padding: 20px 0;
	position: relative;

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		color: $white;
		margin: 0;
		text-align: center;
	}
}

.headtxt-600 {
	font-style: normal;
	font-weight: 600;
	font-size: 39.2571px;
	line-height: 59px;
	background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

/** Login Page Css START **/

.loginmain-content {
	min-height: 100vh;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('/assets/images/loginbg-1.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.login-inrcnt {
		min-height: calc(100vh - 61px);
		padding: 150px 20px 30px 20px;
		position: relative;
	}

	.login-gridmain {
		.login-left {
			position: relative;
		}
	}

	.loginright-mainbox {
		background: rgba(186, 186, 186, 0.07);
		backdrop-filter: blur(5.88857px);
		border-radius: 19.6286px;
		padding: 20px;
		min-height: 460px;

		.loginhead {
			border-bottom: 1.30857px solid rgba(255, 255, 255, 0.1);
			padding: 0 0 5px 0;

			// background: linear-gradient(99.68deg, #C9A337 10.8%, rgba(60, 55, 41, 0) 15.65%, rgba(60, 55, 41, 0) 88.78%, #C9A337 93.34%);
			h5 {
				@extend .headtxt-600;
				text-align: center;
				margin: 0;
			}
		}

		.loginbody {
			padding: 50px 0 10px 0;

			.login-mainbtn {
				background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
				border: 1.30857px solid #3C3729;
				border-radius: 6.54286px;
				min-width: 185px;
				min-height: 50px;
				padding: 0 10px;
				position: relative;

				&:hover,
				&:active,
				&:focus {
					&::before {
						opacity: 1;
					}
				}

				&::before {
					content: '';
					background-image: url('/assets/images/loginbor.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100% 100%;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					transform: scale(1.02);
					opacity: 0;
					@extend .transition;
				}

				.login-gridbtn {
					display: grid;
					grid-template-columns: 36px auto;
					align-items: center;

					.login-ImgBox {
						background: linear-gradient(180deg, #343434 0%, #030303 100%);
						border-radius: 5.23429px;
						width: 36px;
						height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.login-Txt {
						padding: 0 0 0 20px;

						h5 {
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 27px;
							color: $white;
							margin: 0;
							text-align: left;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1200px) {
	.login-gridmain {
		display: grid;
		align-items: center;
		grid-template-columns: auto 560px;

		.login-right {
			position: absolute;
			right: 30px;
			width: 100%;
			max-width: 560px;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.login-left {
		img {
			width: 100%;
		}
	}

	.loginright-mainbox {
		width: 100%;
		max-width: 560px;
		margin: 0 auto;
	}
}


/** Login Page Css END **/




/** Cmn Modal Css **/

.cntwallet-modal {
	.modal-dialog {
		max-width: 860px;

		.modal-content {
			background-color: #161616;
			border-radius: 18.1905px;


			.cntwalt-content {
				width: 100%;
				max-width: 760px;
				margin: 30px auto 30px auto;


				.cntwalt-grid {
					.cntwalt-box {

						background-color: transparent;
						background-image: url('/assets/images/cntwaltbox.png');
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-position: center;
						border: 0;
						min-height: 132px;
						position: relative;
						padding: 20px 15px;


						&:hover {
							&::before {
								opacity: unset;
								z-index: unset;
							}

							.cntwalt-Img,
							.cntwalt-Txt {
								position: relative;
								z-index: 99;
							}
						}


						&::before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-image: url('/assets/images/active-cntwalt.png');
							background-repeat: no-repeat;
							background-position: center;
							background-size: 100% 100%;
							opacity: 0;
							z-index: -1;
						}


						.cntwalt-Img {
							min-height: 65px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.cntwalt-Txt {
							margin: 15px 0 0 0;

							h5 {
								font-style: normal;
								font-weight: 400;
								font-size: 16px;
								line-height: 24px;
								color: $white;
								margin: 0;
							}
						}

					}

				}

				.instant-wallet {
					text-align: center;
					margin: 40px 0 0 0;

					button {
						background-color: #262626;
						backdrop-filter: blur(4.5px);
						border-radius: 50px;
						border: 0;
						min-width: 212px;
						min-height: 55px;
						padding: 0 15px;

						&:hover {
							background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
							backdrop-filter: blur(4.5px);
						}

						.instant-grid {
							display: grid;
							align-items: center;
							grid-template-columns: 40px auto;
						}

						h5 {
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 27px;
							color: $white;
							margin: 0;

						}


					}
				}
			}



		}

	}

	@media only screen and (min-width: 576px) {
		.cntwalt-grid {
			display: grid;
			align-items: center;
		}
	}

	@media only screen and (min-width: 576px) and (max-width: 767px) {

		.cntwalt-grid {
			grid-template-columns: 180px 180px;
			justify-content: center;
			grid-gap: 20px;
		}

	}

	@media only screen and (min-width: 768px) and (max-width: 991px) {

		.cntwalt-grid {
			grid-template-columns: 23% 23% 23% 23%;
			justify-content: space-between;
		}

	}

	@media only screen and (min-width: 992px) {
		.cntwalt-grid {
			grid-template-columns: 178px 178px 178px 178px;
			justify-content: space-between;
		}





	}


	@media only screen and (max-width: 991px) {

		.modal-content {
			margin: 0 20px;
		}

	}

	@media only screen and (max-width: 575px) {

		.cntwalt-box {
			min-width: 178px;
			display: block;
			margin: 0 auto;

			&:not(:last-child) {
				margin-bottom: 30px;
			}

		}

	}

	@media only screen and (max-width: 480px) {
		.modal-title {
			font-size: 25px !important;
		}

	}

}

.cmnmodal {

	.modal-dialog {


		.modal-content {
			padding: 20px;

			.modal-close {
				width: 26px;
				height: 26px;
				background: linear-gradient(140.95deg, #F4C247 28.07%, #EAB144 52%, #D1843D 98.81%, #BD6137 132.1%);
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: unset;
				border-radius: 50%;
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 99;

				&:hover {


					svg {
						transform: rotate(360deg);
					}
				}



				&:not(:disabled):not(.disabled):hover,
				&:hover {
					opacity: unset;
				}

			}

			.modal-body {
				padding: 0;

				.modal-title {
					font-style: normal;
					font-weight: 600;
					font-size: 30px;
					line-height: 45px;
					background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
				}

			}
		}

	}

}


/** Dashboard Css START **/

.menubar-bnt {
	width: 47px;
	height: 47px;
}

.dash-header {
	display: flex;
	align-items: center;
	justify-content: end;
}

.inr-head {
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 45px;
	background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-size: 50% 50%;
	animation: rainbow 10s linear infinite;
}

.header-btn {
	background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
	border: 1px solid #3C3729;
	border-radius: 5px;
	padding: 0 12px;
}

.swap-header {
	button {
		@extend .header-btn;
		transition: .5s all;


		.swapheader-grid {
			display: grid;
			align-items: center;
			grid-template-columns: 20px auto;
			min-height: 32px;
			min-width: 80px;

			h5 {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				color: $white;
				margin: 0;
			}

		}

		&:hover {
			border-color: #EAB144;
		}

	}
}

.dashboard-main {
	.dashboard-inrcnt {
		min-height: 100vh;
		background-image: url('/assets/images/dashboard-bg.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		&.userprofile-mainbg {
			background-image: url('/assets/images/userprofile-bg.png');
		}

		.dash-sidebarmain {
			background-color: #111111;
			width: 300px;
			position: fixed;
			height: 100vh;
			padding: 20px 0 20px 15px;
			@extend .transition;
			z-index: 999;

			.dashlogo {
				text-align: center;
			}

			.dash-sidebarul {
				direction: rtl;
				margin: 25px 0 10px 0;
				padding: 0 0 0 15px;
				height: calc(100vh - 48px - 35px - 20px);
				overflow-y: auto;

				ul {
					direction: ltr;

					li {
						a {
							.dash-ulgrid {
								display: grid;
								align-items: center;
								grid-template-columns: 38px auto;
								position: relative;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 0;
									height: 100%;
									background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
									backdrop-filter: blur(4.5px);
									border-radius: 50px 5px 5px 50px;
									z-index: -1;
									@extend .transition;
								}

								.dash-menuIcon {
									background: linear-gradient(180deg, #343434 0%, #030303 100%);
									border-radius: 50%;
									width: 38px;
									height: 38px;
									display: flex;
									align-items: center;
									justify-content: center;
								}

								.dash-menuTxt {
									margin: 0 0 0 10px;

									h5 {
										font-style: normal;
										font-weight: 400;
										font-size: 18px;
										line-height: 27px;
										color: $white;
										margin: 0;
									}
								}




							}

							&:hover {
								.dash-ulgrid {
									.dash-menuIcon {
										svg {
											path {
												stroke: #F1BD46;
											}
										}
									}

									&::before {
										width: 100%;
									}
								}
							}

						}

						&:not(:last-child) {
							margin: 0 0 20px 0;
						}




					}
				}
			}

		}
	}
}

@media only screen and (min-width: 1200px) {

	.dash-rightcnt {
		margin: 0 0 0 300px;
		padding: 25px 20px 0 20px;
	}

	.dash-header {
		padding: 15px;
		position: fixed;
		top: 0;
		right: 0;
		width: calc(100% - 300px);
		background-color: #000;
	}

	.dashheader-right {
		display: flex;
		align-items: center;
	}

	.dashright-centercnt {
		min-height: calc(100vh - 70px - 25px - 61px);
		margin: 60px 0 10px 0;
	}

}

@media only screen and (max-width: 1199px) {

	.SideMenuOpen {
		.dash-sidebarmain {
			transform: translateX(0);
		}

		.dashright-centercnt,
		footer {
			filter: blur(5px);
			pointer-events: none;
		}

		.menubtn {
			span {
				&:first-child {
					transform: rotate(45deg) translateY(1px) translateX(5px);
				}

				&:nth-child(2) {
					transform: rotate(-45deg);
				}

				&:last-child {
					transform: rotate(45deg) translateY(-8px) translateX(-2px);
				}
			}
		}

	}


	.menubtn {
		background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
		border-radius: 50%;
		width: 47px;
		height: 47px;
		border: 0;
		display: flex;
		align-items: self-start;
		justify-content: center;
		flex-direction: column;
		padding: 0 0 0 8px;





		span {
			display: block;
			height: 2px;
			background-color: #fff;
			border-radius: 2px;
			transition: .5s all;

			&:first-child {
				width: 16px;
			}

			&:nth-child(2) {
				width: 32px;
				margin: 7px 0;
			}

			&:last-child {
				width: 26px;
			}




		}

	}

	.dash-rightcnt {
		padding: 25px 15px 0 15px;
	}

	.dashright-centercnt {
		min-height: calc(100vh - 61px - 25px - 95px);
		margin: 70px 0 25px 0;
	}

	.mobile-headergrid {
		display: grid;
		grid-template-columns: 107px 48px;
		align-items: center;




	}

	.dash-sidebarmain {
		transform: translateX(100%);
		right: 0;
	}

	.dash-header {
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		padding: 15px;
		z-index: 99;
		background-color: #000;
	}

	.twomobile-menu {
		justify-content: space-between;
	}


}


@media only screen and (max-width: 575px) {

	.dash-sidebarmain {
		width: 235px !important;
	}

}



/** Dashboard Css END **/


/** User Profile Page Css START **/

.black-circle {
	background: linear-gradient(180deg, #343434 0%, #030303 100%);
	border-radius: 50%;
}

.userprofile-maincnt {
	.userprofile-inrcnt {

		// margin: 30px 0 0 0;
		.userprofile-boxCnt {
			background-color: #111111;
			border-radius: 10px;
			padding: 15px;

			.userprofile-box {
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);

				.btn-primary {
					font-size: 16px;
				}

				.input-group {

					.form-control,
					.input-group-text {
						background: #0b0b0b;
						border-color: #0B0B0B;
					}
				}

				@extend .transition;

				&:last-child {
					border-bottom: 0px;
					margin-bottom: 0px;
				}

				&:hover {
					background-color: #111111;

					.profile-blackbox {
						svg {
							path {
								stroke: #F1BD46;
							}
						}
					}

				}


				.profile-grid {
					display: grid;
					align-items: center;

					&.profileLeft-grid {
						grid-template-columns: 48px auto;

						.profile-nametxt {
							text-align: left;
							margin: 0 0 0 20px;
						}

					}

					&.profileRight-grid {
						grid-template-columns: auto 48px;

						.profile-nametxt {
							text-align: right;
							margin: 0 20px 0 0;
						}
					}

					.profile-blackbox {
						@extend .black-circle;
						width: 48px;
						height: 48px;
						@include df-ac-jc();
					}

					.profile-nametxt {
						h5 {
							font-style: normal;
							font-weight: 300;
							font-size: 14px;
							line-height: 21px;
							color: $white;
							margin: 0 0 7px 0;
						}

						p {
							font-style: normal;
							font-weight: 600;
							font-size: 16px;
							line-height: 24px;
							color: #EAB144;
							margin: 0;
						}
					}
				}

				h5 {
					&.profile-nametxt {
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 21px;
						color: $white;
						margin: 0 0 7px 0;
					}
				}

			}
		}

		.startgame {
			button {
				min-width: 212px;
				min-height: 55px;
				margin: 30px auto 25px auto;
				display: flex;
				align-items: center;
				justify-content: center;
				@extend .transition;

				svg {
					@extend .transition;
				}

				span {
					margin: 0 0 0 10px;
				}

				&:hover {
					svg {
						path {
							fill: #000;
						}
					}
				}

			}
		}

		.playtxt {
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			color: #EAB144;
			margin: 0;
			text-align: center;
		}
	}

	.disable-btn {
		background: linear-gradient(181.66deg, #212121 1.41%, rgba(0, 0, 0, 0) 148.07%);
		border-radius: 50px;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 30px;
		color: #555555;
		border: 0;
		min-width: 212px;
		min-height: 55px;
		margin: 30px auto 20px auto;
	}

	.profileavatar-box {
		position: relative;
		display: inline-block;

		.avatarbtn {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			transform: translate(0, -37px);

			button {
				min-width: 125px;
				min-height: 32px;


			}
		}


	}

	@media only screen and (max-width: 575px) {

		.userprofile-box {
			text-align: center;
		}

		.profileRight-grid {
			grid-template-columns: 48px auto !important;

			.profile-nametxt {
				order: 2;
				margin: 0 0 0 20px !important;
				text-align: left !important;
			}

		}

	}

}


/** User Profile Page Css END **/





/** Inner Page Cmn Css START **/

.cmntb-ul {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);

	li {
		.nav-link {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: $white;
			border: 0;
			padding: 0 0 3px 0;
			position: relative;
			@extend .transition;

			&:hover,
			&.active {
				color: #D0AD6A;

				&::before {
					width: 100%;
				}
			}

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background-color: #D0AD6A;
				@extend .transition;
			}

			&.active {
				background-color: transparent;
				border: 0;
				color: #D0AD6A;
			}

		}

		&:not(:last-child) {
			margin: 0 15px 0 0;
		}
	}
}

.cmnblack-box {
	background: rgba(217, 217, 217, 0.1);
	border-radius: 5px;
	border: 1px solid transparent;
}

.search-box {
	position: relative;

	input {
		@extend .cmnblack-box;
		outline: 0;
		padding: 5px 30px 5px 10px;
		font-weight: 300;
		font-size: 15px;
		line-height: 22px;
		color: $white;
		@extend .transition;
		position: relative;
		min-width: 259px;
		min-height: 40px;

		&:focus {
			border-color: #FF9D35;
			background-color: transparent;

		}

		&:focus+div svg {
			path {
				stroke: #FF9D35;
			}
		}

		&::placeholder {
			font-style: normal;
			font-weight: 300;
			font-size: 15px;
			line-height: 22px;
			color: #9E9E9E;
		}
	}

	.search-svg {
		position: absolute;
		top: 6px;
		right: 10px;
		@extend .transition;
	}
}

.filter {
	button {
		@extend .cmnblack-box;
		min-width: 107px;
		min-height: 40px;
		padding: 5px 12px;
		@extend .transition;

		&:hover {
			border-color: #FF9D35;
			background-color: transparent;

			.filter-grid {

				svg {
					path {
						stroke: #FF9D35;
					}
				}


				h5 {
					color: #FF9D35;
				}
			}

		}

		.filter-grid {
			@include dg-ac();
			grid-template-columns: 26px auto;

			h5 {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: $white;
				margin: 0;
			}

		}


	}

}

.prevnext-btn {
	text-align: right;
	margin: 20px 0 0 0;

	button {
		min-width: 91px;
		min-height: 30px;
		font-size: 14px;
	}

}

.wearables-select {
	// margin: 0 12px 0 0;

	select {
		background: rgba(58, 58, 58, 0.3);
		border-radius: 5px;
		border: 1px solid transparent;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $white;
		min-height: 40px;
		background-image: url('/assets/images/select-down.png');
		background-repeat: no-repeat;
		background-position: center right 8px;
		appearance: none;
		padding: 5px 25px 5px 10px;
		outline: 0;
		border: 0;

		option {
			background-color: #000;
		}

	}
}

.innerpage-main {
	padding: 0 20px;
	background-image: url('/assets/images/dashboard-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 100vh;

	&.createRoomCnt {
		min-height: unset;
	}

	.inner-headertot {
		padding: 15px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: #000;
		z-index: 99;

		.container {
			@media(min-width:1400px) {
				max-width: 1350px;
			}
		}
	}

	.inner-headermain {
		@include df-ac-jsb();

		.inner-headerright {
			@include df-ac();
		}
	}

	.inner-pagecontent {
		min-height: calc(100vh - 61px);
		padding: 80px 0 25px 0;

		.container {
			@media(min-width:1400px) {
				max-width: 1350px;
			}
		}
	}
}

@media only screen and (min-width: 480px) {
	.inner-headermain {
		.inner-headerright {
			.swap-header {
				display: block;
			}
		}
	}

	.inrpage-mobileswap {
		display: none;
	}
}

@media only screen and (max-width: 479px) {
	.inner-headermain {
		.inner-headerright {
			.swap-header {
				display: none;
			}
		}
	}

	.inrpage-mobileswap {
		@include df-ac-jc();
		margin: 10px 0 0 0;
	}

	.inner-pagecontent {
		padding: 130px 0 25px 0 !important;
	}
}

/** Inner Page Cmn Css END **/

.cmntb-ul {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);

	li {
		.nav-link {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: $white;
			border: 0;
			padding: 0 0 3px 0;
			position: relative;
			@extend .transition;

			&:hover,
			&.active {
				color: #D0AD6A;

				&::before {
					width: 100%;
				}
			}

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background-color: #D0AD6A;
				@extend .transition;
			}

			&.active {
				background-color: transparent;
				border: 0;
				color: #D0AD6A;
			}
		}

		&:not(:last-child) {
			margin: 0 15px 0 0;
		}
	}
}

.cmnblack-box {
	background: rgba(217, 217, 217, 0.1);
	border-radius: 5px;
	border: 1px solid transparent;
}

.search-box {
	position: relative;

	input {
		@extend .cmnblack-box;
		outline: 0;
		padding: 5px 30px 5px 10px;
		font-weight: 300;
		font-size: 15px;
		line-height: 22px;
		color: $white;
		@extend .transition;
		position: relative;
		min-width: 259px;
		min-height: 40px;

		&:focus {
			border-color: #FF9D35;
			background-color: transparent;
		}

		&:focus+div svg {
			path {
				stroke: #FF9D35;
			}
		}

		&::placeholder {
			font-style: normal;
			font-weight: 300;
			font-size: 15px;
			line-height: 22px;
			color: #9E9E9E;
		}
	}

	.search-svg {
		position: absolute;
		top: 6px;
		right: 10px;
		@extend .transition;
	}
}

.filter {
	button {
		@extend .cmnblack-box;
		min-width: 107px;
		min-height: 40px;
		padding: 5px 12px;
		@extend .transition;

		&:hover {
			border-color: #FF9D35;
			background-color: transparent;

			.filter-grid {
				svg {
					path {
						stroke: #FF9D35;
					}
				}

				h5 {
					color: #FF9D35;
				}
			}
		}

		.filter-grid {
			@include dg-ac();
			grid-template-columns: 26px auto;

			h5 {
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: $white;
				margin: 0
			}
		}
	}
}

.prevnext-btn {
	text-align: right;
	margin: 20px 0 0 0;

	button {
		min-width: 91px;
		min-height: 30px;
		font-size: 14px;
	}
}

.next-btn {
	border-radius: 50px;
	background: linear-gradient(180deg, #ff9d35 0%, rgba(0, 0, 0, 0) 100%);
	backdrop-filter: blur(6.3321428298950195px);
	border: 0px solid #bd7427;
	outline: none;
	line-height: 30px;
	color: $white;
	cursor: pointer;
	position: relative;
	z-index: 0;
	min-width: 225px;
	min-height: 55px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 22px 0 0;
	font-size: 20px;
	font-weight: 400;

	&:hover {
		color: #fff;
	}

	&:before {
		content: "";
		background: linear-gradient(90deg, #bc8b43 0%, #f1de82 53.18%, #82601b 71.82%, #9d7227 85.38%, #694d24 100%);
		position: absolute;
		top: -2px;
		left: -2px;
		background-size: 400%;
		z-index: -1;
		filter: blur(5px);
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		animation: glowing 20s linear infinite;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border-radius: 50px;
	}
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 400% 0;
	}

	100% {
		background-position: 0 0;
	}
}

.wearables-select {

	// margin: 0 12px 0 0;
	select {
		background: rgba(58, 58, 58, 0.3);
		border-radius: 5px;
		border: 1px solid transparent;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $white;
		min-height: 40px;
		background-image: url('/assets/images/select-down.png');
		background-repeat: no-repeat;
		background-position: center right 8px;
		appearance: none;
		padding: 5px 25px 5px 10px;
		outline: 0;
		border: 0;

		option {
			background-color: #000;
		}
	}
}

// .search-box 
// {
// 	input 
// 	{
// 		background-color: #D9D9D9;
// 		// opacity: 0.1;
// 		border-radius: 5px;
// 		border: 1px solid transparent;
// 	}
// }

/** Marketplace Page Css START **/

.wearable-cntbox {
	background: linear-gradient(139.05deg, #000000 1.24%, #0F0F0F 47.67%, #000000 99.21%);
	border-radius: 12px;
	padding: 10px;
	min-height: 161px;
	display: inline-block;
	min-width: 155px;
	text-align: center;
}

.mkplace-tabulsearchcnt {
	margin: 10px 0 0 0;

	.cmntb-ul {
		display: inline-flex;
	}

	.mlplace-search {
		.search-box {
			display: inline-block;
		}
	}
}

.mkplace-tabcontent {
	margin: 20px 0 0 0;

	.mkplacebadges-tabcontent {
		.mkplace-inrbox {
			background: rgba(58, 58, 58, 0.3);
			border-radius: 5px;
			padding: 15px 25px;

			.mkplace-gridmain {
				margin: 20px 0;
			}
		}

		.mkplace-badgesbox {
			margin-bottom: 20px;

			.badgesImg-box {
				img {
					border-radius: 12px;
				}
			}

			.badgesCnt-box {
				position: relative;
				top: -15px;

				.badges-cntr {
					text-align: center;

					.badge-icon {
						@include dg-ac();
						grid-template-columns: 20px auto;
						background-color: #D0AD6A;
						backdrop-filter: blur(4.5px);
						border-radius: 50px;
						display: inline-grid;
						min-width: 86px;
						padding: 3px 10px;

						h5 {
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 21px;
							color: $black;
							margin: 0 0 0 5px;
						}
					}
				}

				.badges-btm {
					margin: 5px 0 0 0;

					h5,
					p {
						text-align: center;
					}

					h5 {
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 21px;
						color: $white;
						margin: 0;
					}

					p {
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 24px;
						color: #D0AD6A;
						margin: 0;
					}
				}
			}
		}
	}

	.mkplacewearables-tabcontent {
		.mkplace-inrbox {
			background: rgba(58, 58, 58, 0.3);
			backdrop-filter: blur(28px);
			border-radius: 5px;
			padding: 30px;
		}
	}
}

.WearIcon {
	background: #2D2D2D;
	border-radius: 50px;
	width: 25px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.badgePrcBtn {
	.badge-icon {
		align-items: center;
		grid-template-columns: 20px auto;
		background-color: #D0AD6A;
		-webkit-backdrop-filter: blur(4.5px);
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		display: inline-grid;
		min-width: 86px;
		padding: 3px 10px;

		h5 {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #000;
			margin: 0 0 0 5px;
		}
	}
}

.WearImg {
	margin-bottom: 5px;

	img {
		width: 80px;
		height: 70px;
		object-fit: contain;
	}
}

.mkplacewearables-grid {
	@include dg-ac();
	grid-template-columns: 155px 155px 155px 155px 155px 155px 155px;
	justify-content: space-between;
	gap: 29px;
}

@media only screen and (min-width: 480px) {}

@media only screen and (min-width: 480px) and (max-width: 575px) {

	.mkplace-gridmain {
		@include dg-ac();
		grid-template-columns: 45% 45%;
		justify-content: space-between;
		text-align: center;
	}

	.mkplacewearables-grid {
		grid-template-columns: 45% 45%;
	}

}

@media only screen and (min-width: 576px) {}

@media only screen and (min-width: 576px) and (max-width: 991px) {

	.mkplace-gridmain {
		@include dg-ac();
		grid-template-columns: 30% 30% 30%;
		justify-content: space-between;
		text-align: center;
	}

	.mkplacewearables-grid {
		grid-template-columns: 30% 30% 30%;
	}

}

@media only screen and (min-width: 768px) {
	.mkplace-tabulsearchcnt {
		@include df-ac-jsb();
	}

	.search-box {
		margin: 0 12px;
	}

	.mlplace-search {
		@include df-ac();
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.mkplace-gridmain {
		@include dg-ac();
		grid-template-columns: 23% 23% 23% 23%;
		justify-content: space-between;
		text-align: center;
	}

	.mkplacewearables-grid {
		grid-template-columns: 155px 155px 155px 155px 155px;
	}

}


@media only screen and (min-width: 1200px) and (max-width: 1399px) {

	.mkplace-gridmain {
		@include dg-ac();
		grid-template-columns: 145px 145px 145px 145px 145px 145px;
		justify-content: space-between;
	}

	.mkplacewearables-grid {
		grid-template-columns: 155px 155px 155px 155px 155px 155px;
	}

}

@media only screen and (min-width: 1400px) {

	.mkplace-gridmain {
		@include dg-ac();
		grid-template-columns: 145px 145px 145px 145px 145px 145px 145px 145px;
		justify-content: space-between;
	}

}



@media only screen and (max-width: 767px) {

	.mkplace-tabulsearchcnt {

		text-align: center;

		.mlplace-search {
			margin: 15px 0 0 0;
		}

		ul,
		.mlplace-search {
			justify-content: center;
		}

	}

	.mlplace-search {
		.search-box {
			margin: 12px 0 12px 0;
		}
	}


}

@media only screen and (max-width: 479px) {

	.mkplace-gridmain {
		text-align: center;

		.mkplace-badgesbox {
			max-width: 150px;
			margin: 0 auto;
		}

	}

	.mkplacewearables-grid {
		grid-template-columns: 100%;
	}

}

/** Marketplace Page Css END **/
/** TransactionHistory Page Css START **/

.TrsHstyDv {
	ul.nav-tabs {
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		;

		li.nav-item {
			a.nav-link {
				font-weight: 400;
				font-size: 14px;
				color: #FFFFFF;
				cursor: pointer;
				position: relative;

				&:hover,
				&.active {
					color: #EFCA66;
					transition: all .5s;
					background: transparent;
					border-color: transparent;

					span {
						position: relative;

						&:after {
							content: '';
							position: absolute;
							background: #EFCA66;
							height: 1px;
							width: 100%;
							left: 0;
							top: 27px;
						}
					}
				}
			}
		}
	}

	.ShwRslt {
		@include df-ac-jsb();

		p {
			font-size: 14px;
			color: #FFFFFF;
			margin-bottom: 0;
		}

		select {
			background: #060606;
			border: 1px solid #081112;
			border-radius: 5px;
			color: #fff;
			font-size: 14px;
		}
	}

	.SrchDv {
		position: relative;

		input.form-control {
			background: #171311;
			border-radius: 5px;
			border-color: transparent;
			width: 220px;
			height: 38px;
			font-size: 14px;
			color: #B3A7D2;
		}

		img {
			position: absolute;
			right: 15px;
			top: 13px;
		}
	}

	.CmnTblDv {
		background: #121111;
		border-radius: 5px;
		padding: 1rem;

		.table {
			thead th {
				font-size: 14px;
				color: #9B9B9B;
				font-weight: 400;
			}

			tbody {
				tr {
					&:hover {
						background-color: #242117;
						transition: all .5s;
					}

					td {
						font-weight: 300;
						font-size: 16px;
						color: #FFFFFF;
						white-space: nowrap;

						&.completed {
							color: #00FF38;
						}

						&.Warning {
							color: #FF6847;
						}
					}
				}
			}
		}
	}

	.entries-pagination {
		@include df-ac-jsb();
		flex-wrap: wrap;

		.entries-box {
			p {
				color: #fff;
				font-size: 14px;
				margin: 0;
			}
		}

		.tbl-pagination ul {
			@include df-ac();

			li:not(:first-child):not(:last-child) {
				width: 26px;
				height: 27px;
				border-radius: 5px;
			}

			li:not(:first-child):not(:last-child) a {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #fff;
				width: 100%;
				height: 100%;
				@include df-ac-jc();
				border-radius: 5px;
			}

			li:not(:first-child):not(:last-child) a:hover {
				background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
				backdrop-filter: blur(4.5px);
				border-radius: 50px;
				color: #fff;
			}

			li:first-child,
			li:last-child {
				width: 21px;
				height: 27px;
			}

			li:first-child a,
			li:last-child a {
				width: 100%;
				height: 100%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #0C0C0C;
				border: 1px solid #242117;
				border-radius: 5px;
			}

			li:first-child a:hover,
			li:last-child a:hover {
				background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
				backdrop-filter: blur(4.5px);
			}

			li:first-child a:hover img,
			li:last-child a:hover img {
				filter: brightness(0);
			}

			li:first-child {
				margin: 0 10px 0 0;
			}

			li:last-child {
				margin: 0 0 0 10px;
			}
		}
	}
}

/** TransactionHistory Page Css END **/


/** Myresult Page Css START **/

.MyRsltDv {
	.MyRsltDvCnt {
		background: #111111;
		border-radius: 20px;
		@include df-ac-jsb();

		@media(max-width:768px) {
			flex-wrap: wrap;
		}

		.MyRsltDvCntLd {
			@include df-ac();

			@media(max-width:768px) {
				flex-wrap: wrap;
			}

			flex-grow: 5;

			.MyRsltDvCntLPrgDv {
				width: 100%;

				.MyRsltDvCntLPrgDvTx {
					@include df-ac();

					@media(max-width:768px) {
						flex-wrap: wrap;
					}

					p {
						font-size: 18px;
						color: #FFFFFF;
						margin-bottom: 0
					}

					span {
						font-weight: 300;
						font-size: 18px;
						color: #9B9B9B;

						@media(max-width:575px) {
							margin-left: 0.25 !important;
						}
					}
				}

				.MyRsltDvCntLPrgDvCnt {
					@include df-ac();

					@media(max-width:768px) {
						flex-wrap: wrap;
					}

					.progress {
						background-color: #303030;
						height: 10px;
						width: 75%;

						@media(max-width:420px) {
							width: 100%;
						}

						.progress-bar {
							background: #D0AD6A;
							border-radius: 50px;
						}
					}

					span {
						font-weight: 300;
						font-size: 18px;
						color: #FFFFFF;

						@media(max-width:420px) {
							margin-left: 0 !important;
							margin-top: 0.5rem;
						}
					}
				}
			}
		}

		.MyRsltDvCntRd {
			flex-grow: 5;
			text-align: right;

			@media(max-width:768px) {
				text-align: left;
			}

			.claim-btn {
				background: #282828;
				border-radius: 20px;
				min-width: 129px;
				min-height: 45px;
				font-size: 16px;
				color: #FFFFFF;
				border: 0;

				&:hover,
				&.active {
					background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
					backdrop-filter: blur(4.5px);
					transition: all .5s;
				}
			}
		}
	}
}

/** Myresult Page Css END **/

/** Settings Page Css START **/

.SetgsDv {
	background: #111111;
	border-radius: 5px;
	min-height: 439px;

	h3 {
		font-weight: 400;
		font-size: 24px;
		color: #FFFFFF;
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
		padding-bottom: 15px;
		margin-bottom: 1.5rem;
	}

	.SetgsDvCnt {
		@include df-ac-jsb();

		label {
			font-weight: 300;
			font-size: 20px;
			color: #FFFFFF;
			margin-bottom: 0;
		}

		.toggle-button-cover {
			display: table-cell;
			position: relative;
			box-sizing: border-box;
		}

		.button-cover {
			height: 100px;
			margin: 20px;
			background-color: #fff;
			box-shadow: 0 10px 20px -8px #c5d6d6;
			border-radius: 4px;
		}

		.button-cover:before {
			counter-increment: button-counter;
			content: counter(button-counter);
			position: absolute;
			right: 0;
			bottom: 0;
			color: #d7e3e3;
			font-size: 12px;
			line-height: 1;
			padding: 5px;
		}

		.button-cover,
		.knobs,
		.layer {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.buttontg {
			position: relative;
			top: 50%;
			width: 69px;
			height: 28px;
			margin: 0px auto 0 auto;
			overflow: hidden;
		}

		.checkbox1 {
			position: relative;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			opacity: 0;
			cursor: pointer;
			z-index: 3;
		}

		.knobs {
			z-index: 2;
		}

		.layer {
			width: 100%;
			background-color: #232323;
			transition: 0.3s ease all;
			z-index: 1;
		}

		.buttontg.r,
		.buttontg.r .layer {
			border-radius: 100px;
		}

		#button-3 .knobs:before {
			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			width: 25px;
			height: 25px;
			color: #fff;
			font-size: 10px;
			font-weight: bold;
			text-align: center;
			line-height: 1;
			padding: 9px 4px;
			background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
			backdrop-filter: blur(4.5px);
			border-radius: 50%;
			transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
		}

		#button-3 .knobs:after {
			content: "ON";
			position: absolute;
			top: 4px;
			right: 10px;
			font-weight: 500;
			font-size: 14px;
			color: #fff;
		}

		#button-3 .checkbox1:active+.knobs:before {
			width: 46px;
			border-radius: 100px;
		}

		#button-3 .checkbox1:checked:active+.knobs:before {
			margin-left: -26px;
		}

		#button-3 .checkbox1:checked+.knobs:before {
			content: "";
			left: 42px;
			background: #4B4B4B;
			backdrop-filter: blur(4.5px);
		}

		#button-3 .checkbox1:checked+.knobs:after {
			content: 'OFF';
			left: 10px;
			position: absolute;
			font-weight: 500;
			font-size: 14px;
			top: 4px;
			color: #FFFFFF
		}

		#button-3 .checkbox1:checked~.layer {
			background-color: #232323;
		}
	}
}

/** Settings Page Css END **/

/** Mybadges page CSS START **/
.SrchDv {
	position: relative;
	display: flex;
	justify-content: flex-end;

	input.form-control {
		background: #171311;
		border-radius: 5px;
		border-color: transparent;
		width: 220px;
		height: 38px;
		font-size: 14px;
		color: #B3A7D2;
	}

	img {
		position: absolute;
		right: 15px;
		top: 13px;
	}
}

.MybdgDv {
	.MybdgDvCnt {
		background: #121211;
		border-radius: 5px;

		.MybdgDvCntDt {
			.BdgCntDv {
				position: relative;
				top: -15px;
				text-align: center;
			}

			.tknval {
				background: #D0AD6A;
				backdrop-filter: blur(4.5px);
				border-radius: 50px;
				min-width: 86px;
				min-height: 26px;
				font-weight: 400;
				font-size: 14px;
				color: #000;
				display: inline-flex;
				justify-content: center;
				align-items: center;

			}

			p {
				font-weight: 400;
				font-size: 14px;
				color: #fff;
				text-align: center;
			}
		}
	}
}

.prevnext-btn {
	text-align: right;
	margin: 20px 0 0 0;

	.btn-primary {
		background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 30px;
		color: #fff;
		padding: 0;
		outline: 0;
		border: 0;
		position: relative;
		border: 0;

		&:hover {
			background: linear-gradient(90deg, #FF8C21 13.4%, #E6F294 86.6%);
			color: #060606;
			box-shadow: none;
		}
	}
}

.prevnext-btn button {
	min-width: 91px;
	min-height: 30px;
	font-size: 14px;
}

/** Mybadges page CSS End **/

.MywrlsDv {
	.mwrls-search {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;

		.wearables-select {
			@media(max-width:420px) {
				margin-right: 0 !important;
			}

			select {
				background-color: #121111 !important;
				border-color: transparent;
				background: url('../src/assets/images/polygon.png') no-repeat;
				background-position: 98%;
				appearance: none;
				;
				min-width: 142px;
				min-height: 38px;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF
			}
		}
	}

	.MywrlsDvCnt {
		background: #111111;
		border-radius: 5px;

		.MywrlsDvDt {
			background: linear-gradient(139.05deg, #000000 1.24%, #0F0F0F 47.67%, #000000 99.21%);
			border-radius: 12px;
			min-height: 161px;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-bottom: 1rem;

			.mlfmldv {
				position: absolute;
				top: 10px;
				right: 15px;
			}

			.BdgCntDv {
				position: relative;
				top: 0px;
				text-align: center;
			}

			.tknval {
				background: #D0AD6A;
				backdrop-filter: blur(4.5px);
				border-radius: 50px;
				min-width: 86px;
				min-height: 26px;
				font-weight: 400;
				font-size: 14px;
				color: #000;
				display: inline-flex;
				justify-content: center;
				align-items: center;

			}

			.MywrlsDvDtIg {
				min-height: 106px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

// *******ndcss**********
// Filter pop

.modal-dialog-filter {
	.modal-content {
		background: #1D1C1C;
		border-radius: 15px;
		border: none;

	}

	.modal-body {
		padding: 2rem;
	}

}

.fliter-header {
	position: relative;
	border-bottom: 0px;
	justify-content: center;

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 28px;
		color: $white;
	}

	h3 {
		background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
	}

	.singup-close {
		position: absolute;

		right: 16px;
		top: 19px;

	}
}

.fil_cd1 {
	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		color: #FFFFFF;
	}
}

.dbranf {
	border-bottom: 2px solid #393939;
	padding-bottom: 10px;


	[slider]>div>[inverse-left] {
		position: absolute;
		left: 0;
		height: 6px;
		border-radius: 10px;
		background: #393939;
		margin: 0 7px;
	}

	[slider]>div>[inverse-right] {
		position: absolute;
		right: 0;
		height: 6px;
		border-radius: 10px;
		background: #393939;
		margin: 0 7px;
	}

	[slider]>div>[range] {
		position: absolute;
		left: 0;
		height: 6px;
		border-radius: 14px;
		background: #FF9736;
	}

	[slider]>div>[thumb] {
		position: absolute;
		top: -6px;
		z-index: 2;
		height: 17px;
		width: 17px;
		text-align: left;
		margin-left: -11px;
		cursor: pointer;
		background: linear-gradient(140.95deg, #F4C247 28.07%, #EAB144 52%, #D1843D 98.81%, #BD6137 132.1%);
		border-radius: 50%;
		outline: none;
	}

	div[slider]>input[type=range]::-ms-thumb {
		pointer-events: all;
		width: 17px;
		height: 17px;
		border-radius: 0px;
		border: 0 none;
		background: red;
	}

	div[slider]>input[type=range]::-moz-range-thumb {
		pointer-events: all;
		width: 17px;
		height: 17px;
		border-radius: 0px;
		border: 0 none;
		background: red;
	}

	div[slider]>input[type=range]::-webkit-slider-thumb {
		pointer-events: all;
		width: 17px;
		height: 17px;
		border-radius: 0px;
		border: 0 none;
		background: red;
		-webkit-appearance: none;
	}

	div[slider]>input[type=range]::-ms-fill-lower {
		background: transparent;
		border: 0 none;
	}

	div[slider]>input[type=range]::-ms-fill-upper {
		background: transparent;
		border: 0 none;
	}

	[slider]>input[type=range] {
		position: absolute;
		pointer-events: none;
		-webkit-appearance: none;
		z-index: 3;
		height: 14px;
		top: -2px;
		width: 100%;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		filter: alpha(opacity=0);
		-moz-opacity: 0;
		-khtml-opacity: 0;
		opacity: 0;
	}

	div[slider]>input[type=range]::-ms-track {
		-webkit-appearance: none;
		background: transparent;
		color: transparent;
	}

	div[slider]>input[type=range]::-moz-range-track {
		-moz-appearance: none;
		background: transparent;
		color: transparent;
	}

	div[slider]>input[type=range]:focus::-webkit-slider-runnable-track {
		background: transparent;
		border: transparent;
	}

	div[slider]>input[type=range]:focus {
		outline: none;
	}

	div[slider]>input[type=range]::-ms-tooltip {
		display: none;
	}

	[slider]>div>[sign] {
		opacity: 0;
		position: absolute;
		margin-left: -11px;
		top: -39px;
		z-index: 3;
		background: #FF9736;
		color: #fff;
		width: 17px;
		height: 17px;
		border-radius: 28px;
		-webkit-border-radius: 28px;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		text-align: center;
	}

	[slider]>div>[sign]:after {
		position: absolute;
		content: '';
		left: 0;
		border-radius: 16px;
		top: 19px;
		border-left: 14px solid transparent;
		border-right: 14px solid transparent;
		border-top-width: 16px;
		border-top-style: solid;
		border-top-color: #1ABC9C;
	}

	[slider]>div>[sign]>span {
		font-size: 12px;
		font-weight: 700;
		line-height: 28px;
	}

	[slider]:hover>div>[sign] {
		opacity: 1;
	}

	[slider] {
		position: relative;
		height: 14px;
		border-radius: 10px;
		text-align: left;
		margin: 29px 0 10px 0;
	}

	[slider]>div {
		position: absolute;
		left: 13px;
		right: 15px;
		height: 14px;
	}

}

.cte_bh {
	margin-top: 10px;

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		color: #FFFFFF;
		margin-bottom: 14px;
	}

	select {
		background: #393939;
		border-radius: 5px;
		height: 31px;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #FFFFFF;
		padding: 5px 10px;
		border: none;
		background-image: url("/assets/images/dnarrow.svg");
		background-repeat: no-repeat;
		background-position: 94% 50%;
		-webkit-appearance: none;
		appearance: none;

		&:focus {
			background: #393939;
			border-radius: 5px;
			height: 31px;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			color: #FFFFFF;
			padding: 5px 10px;
			border: none;
			background-image: url("/assets/images/dnarrow.svg");
			background-repeat: no-repeat;
			background-position: 94% 50%;
			-webkit-appearance: none;
			appearance: none;

		}
	}

	.selcd {
		margin: 10px 10px 5px 0px;
		min-width: 90px;
		min-height: 32px;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #FFFFFF;
		background: rgba(58, 58, 58, 0.5);
		border-radius: 5px;
		padding: 5px 10px;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
	}

}

.filpoptl {
	background: rgba(186, 186, 186, 0.07);
	backdrop-filter: blur(18.5px);

}

.cdcres {
	h2 {
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		margin-bottom: 15px;
		color: #FFFFFF;
	}
}

.bnand {
	@include df-ac();
	flex-wrap: wrap;

	.btns1 {
		background: #D0AD6A;
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		min-width: 138px;
		min-height: 45px;
		@include df-ac();
		justify-content: start;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		color: #000000;

	}

	.btns2 {
		border-radius: 50px;
		min-width: 138px;
		min-height: 45px;
		background: #090909;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		color: #D0AD6A;
		@include df-ac();
		justify-content: start;

	}

	.btns3 {
		min-width: 138px;
		min-height: 45px;
		background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		@include df-ac-jc();
		border: none;
	}
}

.bnsec {
	margin-top: 15px;

	label {
		background: #090909;
		border-radius: 5px;
		padding: 5px 8px;
		margin-right: 8px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;

		span {
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			color: #EDD573;
			margin-left: 8px;

		}

	}
}

.cdcres2 {
	margin-top: 20px;

	h2 {
		font-style: normal;
		font-weight: 300;
		font-size: 20px;
		color: #FFFFFF;
		margin-bottom: 15px;
	}
}

.LtstSlsTbl {
	background: #090909;
	border-radius: 5px;
	padding: 10px 20px;
	height: 241px;
	scrollbar-width: thin;
	scrollbar-color: #393939 #bbb;


	&::-webkit-scrollbar {
		width: 4px;
		height: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #393939;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		border-radius: 0px;
	}

	thead {
		tr {
			th {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				color: #9B9B9B;
				white-space: nowrap;
			}
		}
	}

	.mytbdy {
		tr {
			td {
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				color: #FFFFFF;
				white-space: nowrap;

				.cdf {
					font-weight: 300;

					font-size: 14px;
				}
			}
		}

	}
}

@media only screen and (max-width: 991px) {
	.cdcres {
		margin-top: 20px;
	}
}

.ptm_ps {
	.play-gridmain {
		grid-template-columns: 129px 129px;
		justify-content: center;


		.playmain-box {
			background: rgba(255, 255, 255, 0.07);

			// background: linear-gradient(to right, #000, #000) padding-box, linear-gradient(135deg, #BB8E27 4%, #000 20%, #000 80%, #AF821B 100%) border-box;
			backdrop-filter: blur(4.5px);
			border-radius: 15px;
			margin: 10px;
			min-height: 96px;
			@include df-ac-jc();
			text-align: center;
			@extend .transition;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-image: url('/assets/images/border.png');
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% 100%;
				@extend .transition;
			}

			&:hover {
				backdrop-filter: blur(0);
				transform: skew(-3deg, -3deg);
				background: linear-gradient(180deg, rgba(255, 215, 72, 0.17) 0%, rgba(255, 255, 255, 0.17) 100%);
			}

			h5 {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				color: #FFFFFF;
				margin: 12px 0 0 0;
			}
		}
	}

}

@media only screen and (min-width: 567px) {
	.dialog-pym {
		max-width: 421px;
	}

	.dialog-pym2 {
		max-width: 430px;

	}

}

@media only screen and (max-width: 767px) {
	.ptm_ps .play-gridmain .playmain-box {
		margin: auto;
		margin-top: 10px;
	}

	.fliter-header .singup-close {
		right: 6px;
		top: 2px;

	}

	.modal-dialog-filter .modal-body {
		padding: 1rem;
	}
}

.sbsren {
	h2 {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #FFFFFF;
		margin-bottom: 8px;

	}

	@include df-ac-jsb();


}

.int_pym {
	.input-group {
		background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
		border: 1px solid #3C3729;
		border-radius: 28px;

		input {
			background-color: transparent;
			border: none;
			text-align: right;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			color: #FFFFFF;
			height: 44px;
		}

		.input-group-text {
			background-color: transparent;
			border: none;
			font-style: normal;
			font-weight: 300;
			font-size: 14px;
			color: #FFFFFF;

		}
	}
}

.int_pymam {
	padding-top: 15px;

	h2 {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		margin-bottom: 10px;
	}

	button {
		min-width: 129px;
		min-height: 42px;
		margin-top: 10px;

	}

}

.drp_ki {
	button {
		background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
		border: 1px solid #3C3729;
		border-radius: 28px;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #FFFFFF;
		width: 100%;
		height: 44px;
		@include df-ac-jsb();

		&::after {
			display: none;
		}

		&:hover {
			color: #FFFFFF;
		}
	}

}

.crdexpd {
	background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
	border: 1px solid #3C3729;
	border-radius: 28px;
	padding: 20px 20px;
	margin-top: 18px;

	h2 {
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #FFFFFF;
	}

}

.frnin {
	.input-group {
		background: rgba(0, 0, 0, 0.3);
		border: 1px solid #3C3729;
		border-radius: 28px;

		input {
			background-color: transparent;
			border: none;
			box-shadow: none;
			height: 41px;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
		}
	}
}

.nji_ki {
	input {
		background: rgba(0, 0, 0, 0.3);
		border: 1px solid #3C3729;
		border-radius: 28px;
		height: 41px;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		color: #FFFFFF;

		&:focus {
			background: rgba(0, 0, 0, 0.3);
			border: 1px solid #3C3729;
			border-radius: 28px;
			height: 41px;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			color: #FFFFFF;
		}

		&::placeholder {
			color: #787878;
		}
	}
}

.tke_ki {
	text-align: center;

	img {
		max-width: 200px;
	}

	h2 {
		background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

}

/** Wallet page CSS START **/

.userprofile-mainbg {
	background-image: url('/assets/images/userprofile-bg.png');

	.inner-headertot {
		background: transparent;
	}

	.InrCmnCntDv {
		padding-top: 50px;

		.RcryPhsDv {
			.inr-sub-head {
				font-weight: 400;
				font-size: 19.7px;
				line-height: 29px;
				text-align: center;
				color: #9B9B9B;
			}

			.RcryPhsInrCnt {
				margin-top: 3rem;

				.RcryPhsInrCntDts {
					background: #161616;
					border-radius: 7.03571px;
					margin-bottom: 1rem;
					padding: 14px 15px;
					min-height: 64px;

					p {
						font-size: 24px;
						text-align: center;
						color: #FFFFFF;
						margin-bottom: 0;
					}
				}
			}
		}
	}

}

.CmnBtnFrSt {
	.btn {
		width: 233px;
		height: 56px;
		font-weight: 400;
		font-size: 19.7px;
	}

	.btn-brd-primary {
		background: transparent;
		border: 1.40714px solid #BD7427;
		border-radius: 70.3571px;
		color: #fff;

		&:hover {
			background: linear-gradient(90deg, #FF8C21 13.4%, #E6F294 86.6%);
			color: #060606;
			box-shadow: none;
			border: 0;

			img {
				filter: brightness(0);
			}
		}
	}
}

.modal {
	background: rgba(186, 186, 186, 0.07);
	-webkit-backdrop-filter: blur(18.5px);
	backdrop-filter: blur(18.5px);

	.CmnMdlDV {
		.modal-content {
			background: #161616;
			border-radius: 7.03571px;

			.CnfmImg {
				width: 200px;
			}

			p {
				font-weight: 500;
				font-size: 26px;
				color: #FFFFFF;
				text-align: center;
			}

			.btn-dne {
				width: 166px;
				height: 44px;
				font-size: 14px;
				text-align: center;
				color: #FFFFFF;
			}

			h3 {
				font-weight: 600;
				font-size: 30px;
				background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				text-align: center;
				margin-bottom: 2rem;
			}

			.qrsnmb {
				padding: 33px 72px;
			}

			.clsImg {
				position: absolute;
				right: 20px;
				top: 40px;
				cursor: pointer;
			}
		}
	}
}

.ordv p {
	font-weight: 700;
	font-size: 18px;
	line-height: 23px;
	text-align: center;
	color: #FFFFFF;
	margin-bottom: 0;
}


// Marketplace cloth scss 
.MkplcClthsBg {
	background: rgba(58, 58, 58, 0.3);
	border-radius: 5px;
	padding: 20px 50px 50px;

	@media (max-width: 767px) {
		padding: 20px 30px 50px;
	}

	.wearable-cntbox {
		background: linear-gradient(139.05deg, #000000 1.24%, #212121 47.67%, #000000 99.21%);
		border-radius: 21.2971px;
		min-width: 182px;
		padding: 30px 20px 15px;
		transition: 0.4s all ease-in-out;
		border: 1.25px solid;

		&:hover {
			background: linear-gradient(139.05deg, #000000 1.24%, #212121 47.67%, #000000 99.21%) padding-box, linear-gradient(180deg, #F4DDA5 0%, rgba(66, 48, 5, 0.47) 100%) border-box;
			transition: 0.4s all ease-in-out;
			border: 1.25px solid;
			border-image-source: linear-gradient(180deg, #F4DDA5 0%, rgba(66, 48, 5, 0.47) 100%);
		}
	}

	.badgePrcBtn .badge-icon {
		padding: 3px 15px;
	}
}

.clothSldr {
	.slick-slide {
		text-align: center;
	}

	.slick-prev:before {
		content: url(/assets/images/sldr-arrw1.svg);
		opacity: 1;
	}

	.slick-next:before {
		content: url(/assets/images/sldr-arrw2.svg);
		opacity: 1;
	}
}

.cusCheckBx {
	.form-group {
		display: block;
		margin-bottom: 15px;
	}

	.form-group input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer;
	}

	.form-group label {
		position: relative;
		cursor: pointer;
		font-size: 16px;
		line-height: 24px;
		color: $white;
	}

	.form-group label:before {
		content: '';
		-webkit-appearance: none;
		background: #393939;
		border-radius: 2px;
		border: 2px solid #393939;
		padding: 6px;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		cursor: pointer;
		margin-right: 10px;
	}

	.form-group input:checked+label:after {
		content: '';
		display: block;
		position: absolute;
		top: 2px;
		left: 9px;
		width: 8px;
		height: 14px;
		border: solid #FF9736;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
}

.WearLftImg {
	background: linear-gradient(139.05deg, #000000 1.24%, #0F0F0F 47.67%, #000000 99.21%);
	border-radius: 12px;
	width: 100%;
	min-height: 480px;
	display: flex;
	align-items: center;
}

.PyAMntDtls {
	background: #232323;
	border-radius: 5px;
	padding: 15px;

	h4 {
		font-weight: 400;
		font-size: 20px;
		line-height: 30px;
		color: $white;
		margin-bottom: 0;

		span {
			font-weight: 300;
			font-size: 14px;
			line-height: 21px;
			color: $white;
		}
	}
}

.int_pymam {
	.btn-primary {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

// Marketplace cloth scss End
// Edit profile Scss 
.EdtForm {
	.form-control {
		background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
		border: 1px solid #3C3729;
		border-radius: 28px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $white;
		min-height: 44px;
	}

	.FrmLbl {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $white;
	}
}

// Edit profile Scss End
// Play History page scss 
.HstryTbl {

	.table td,
	.table th {
		vertical-align: middle;
		border-top: unset;
		white-space: nowrap;
	}

	.PairDtls {
		h4 {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: $white;
			margin-bottom: 0;
		}

		p {
			font-weight: 300;
			font-size: 18px;
			line-height: 27px;
			color: #9B9B9B;
			margin-bottom: 0;
		}
	}

	.PrcBtn {
		background: linear-gradient(180deg, #343434 0%, #030303 100%);
		border-radius: 4px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #41FF54;
		min-height: 47px;
		min-width: 135px;
		border: unset;
	}

	tr {
		background: #111111;
		border-radius: 20px;
	}

	.table tr td:first-child {
		border-radius: 20px 0 0 20px;
	}

	.table tr td:last-child {
		border-radius: 0 20px 20px 0;
	}

	.table {
		border-collapse: separate;
		border-spacing: 0 0.5em;
	}
}

.PlyHsTbb {
	.cmntb-ul li {
		padding-right: 10px;
	}
}

// Play History page scss End
// Create Room page scss 
.CreatRoomFrm.EdtForm {
	.form-control {
		border-radius: 6.5px;
		min-height: 45px;
	}

	.int_pym .input-group {
		border-radius: 6.5px;

		.form-control {
			background: transparent;

			&::placeholder {
				color: $white;
			}
		}
	}
}

.loginmain-content .CreatRoomMainBox {
	min-height: 360px;
	padding: 20px 27px;
}

.login-gridmain .login-right {
	z-index: 1;
}

.InvtFrm {
	.int_pym .input-group {
		.form-control {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.InvtGmDtlsSec {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	h4 {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: $white;
	}

	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #F4C247;
		margin-bottom: 10px;
	}
}

.InvtGmDtlsBox {
	background: linear-gradient(180deg, rgba(58, 58, 58, 0.3) 0%, rgba(69, 69, 69, 0) 100%);
	border: 1.30857px solid #3C3729;
	border-radius: 6.54286px;
	padding: 12px;
}

.RoomMain {
	padding: 0;

	.inner-headertot {
		background-color: transparent;
	}

	.loginmain-content .login-inrcnt {
		min-height: calc(100vh - 78px);
		padding: 78px 20px 30px 20px;
		display: flex;
		align-items: center;

		@media (max-width: 480px) {
			min-height: calc(100vh - 122px);
			padding: 122px 0px 30px;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.RoomMain .login-gridmain .login-right {
		right: 20px;
	}
}

@media (max-width: 480px) {

	.headtxt-600,
	.loginmain-content .loginright-mainbox .loginhead h5 {
		font-size: 25px;
		line-height: 32px;
	}

	.loginmain-content .CreatRoomMainBox {
		min-height: 360px;
		padding: 12px 15px 15px;
	}
}

// Create Room page scss End
@media (min-width:1400px) {
	.CusContainer {
		max-width: 1350px;
	}
}

.disclModal {

	.modal-content {
		background: #161616;
		border-radius: 23px;
		padding: 20px;

		.modal-header {
			h2 {
				&.discTitle {
					font-size: 40px;
					background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
					background-size: auto;
					background-clip: border-box;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
					background-size: 50% 50%;
					animation: rainbow 5s linear infinite;
				}
			}
		}

		.modal-body {
			background: rgba(255, 255, 255, 0.07);
			backdrop-filter: blur(6.82615px);
			border-radius: 10px;
			max-height: 350px;
			overflow-y: auto;

			p {
				color: #9B9B9B;
				font-size: 15px;
				line-height: 29px;
			}
		}
	}
}

.updPrfModal {
	.modal-content {
		background: #161616;
		border-radius: 23px;
		padding: 20px;

		.modal-header {
			h2 {
				&.discTitle {
					font-size: 30px;
					background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
					background-size: auto;
					background-clip: border-box;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
					background-size: 50% 50%;
					animation: rainbow 5s linear infinite;
				}
			}
		}

		.input-group {

			.form-control,
			.input-group-text {
				background: #0b0b0b;
				border-color: #0B0B0B;
			}
		}

		.form-label {
			font-weight: 300;
			font-size: 14px;
			line-height: 21px;
			color: #fff;
		}
	}
}

.int_pymam {
	.btn-secondary {
		background: #262626;
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		border-color: transparent;
		transition: all 0.5s;

		&:hover {
			border-color: #262626;
			background: transparent;
		}
	}
}

// ***********************
// Choose_an_avathar

@media only screen and (min-width: 1400px) {
	.cuscontainer {
		max-width: 1320px;
	}

}

.cavt {
	min-height: 700px;

}

.crs_man {
	position: relative;
	margin-top: 60px;
	z-index: 2;

	.crs_manbg {
		position: absolute;
		width: 100%;
		top: 0px;
		left: 0px;
		right: 0px;
		z-index: -1;
		top: 41px;

		img {
			width: 100%;
			min-height: 601px;

		}
	}

	.vrRevSldC {
		position: relative;
		z-index: 3;
		padding-top: 108px;
		padding-bottom: 50px;

		img {
			display: unset;
		}

		.lgfls {
			display: none;
		}

		.avnm {
			position: absolute;
			right: 20px;
			top: 120px;
			background: linear-gradient(91.41deg, rgba(2, 1, 0, 0) 0.91%, #392B0F 39.11%, rgba(2, 1, 0, 0) 87.07%);
			display: inline-block;

			h2 {
				font-style: normal;
				font-weight: 600;
				font-size: 26px;
				background: linear-gradient(210deg, #FF8C21 13.4%, #E6F294 86.6%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}
	}

	.vrRevSldR {
		.vrRevSldC.slick-active {
			.lgfls {
				display: none;
			}

			.rds_ki {
				display: inline-block;
			}
		}

		.vrRevSldC.slick-active+.vrRevSldC.slick-active+.vrRevSldC.slick-active {
			.lgfls {
				display: none;
			}

			.rds_ki {
				display: inline-block;
			}
		}

		.slick-active+.slick-active+.slick-active {
			.vrRevSldC {
				.lgfls {
					display: none;
				}

				.rds_ki {
					display: inline-block;
				}
			}
		}

		.vrRevSldC.slick-active+.vrRevSldC.slick-active {
			.lgfls {
				display: inline-block;
				position: absolute;
				top: -7px;
				left: 53%;
				z-index: -1;
				transform: translateX(-50%);

			}

			.rds_ki {
				display: none;
			}
		}

		.slick-active+.slick-active {
			.vrRevSldC {
				.lgfls {
					display: inline-block;
					position: absolute;
					top: -7px;
					left: 53%;
					z-index: -1;
					transform: translateX(-50%);
				}

				.rds_ki {
					display: none;
				}
			}
		}

	}

	.rds_ki {
		position: absolute;
		top: 15px;
		left: 50%;
		transform: translateX(-50%);

	}

	.slick-arrow {
		z-index: 222;

		&::before {
			display: none;
		}

		&.slick-prev {
			left: 16px;
		}

		&.slick-next {
			right: 44px;

		}
	}
}

.vnc_btn {
	text-align: center;

	.btn {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: #FFFFFF;
		min-width: 127px;
		min-height: 35px;
		@include df-ac-jc();
		background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
		backdrop-filter: blur(4.5px);
		border-radius: 50px;
		border: none;
		margin: auto;
	}

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		color: #5CFF42;
		margin-top: 7px;
	}

}

@media only screen and (max-width: 1399px) {
	.crs_man .vrRevSldC .avnm h2 {
		font-size: 16px;
	}

}

@media only screen and (max-width: 767px) {
	.crs_man .vrRevSldR .vrRevSldC.slick-active .lgfls {
		display: inline-block;
		position: absolute;
		top: -7px;
		left: 53%;
		z-index: -1;
		transform: translateX(-50%);
	}

	.crs_man .vrRevSldR .vrRevSldC.slick-active .rds_ki {
		display: none;
	}

	.crs_man .vrRevSldC .avnm {
		position: relative;
		top: unset;
		right: unset;
		left: 50%;
		transform: translateX(-50%);
		background: transparent;

	}

	.crs_man .vrRevSldC .avnm h2 {
		font-size: 28px;
		margin-top: 30px;
	}

	.crs_man .vrRevSldC {
		padding-bottom: 1px;
	}


}

.cgthi {
	.vrRevSldC {
		padding: 20px 20px;
	}

	.vrRevSldCin {
		background: linear-gradient(139.05deg, #000000 1.24%, #212121 47.67%, #000000 99.21%);
		border: 1px solid transparent;
		padding: 20px 20px;
		text-align: center;
		border-radius: 21.2971px;

		&:hover {
			background: linear-gradient(139.05deg, #000000 1.24%, #212121 47.67%, #000000 99.21%) padding-box, linear-gradient(90deg, #F4DDA5 50%, #434343 100%) border-box;

		}

		.drsim {
			min-height: 290px;
		}

		img {
			display: unset;
		}

	}
}

.cgthi {
	.slick-arrow {
		z-index: 222;

		&::before {
			display: none;
		}

	}

	.slick-next,
	.slick-prev {
		&::before {
			display: none;
		}
	}

}

.badgePrcBtn {
	.badge-icon_bh {
		grid-template-columns: auto;
	}

}

.vrRevSldCin {
	position: relative;
	z-index: 222;

	// &::after{
	//     content: "";
	//     background-color: red;
	//     background: linear-gradient(139.05deg, #000000 1.24%, #212121 47.67%, #000000 99.21%);
	//     width: calc(100% + 2px);
	//     height: calc(100% + 2px);
	//     position: absolute;
	//     top: -1px;
	//     left: -1px;
	//     z-index: -1;

	// }
}

/* Poker Game CSS Start */

.PkrGmDv {
	position: relative;

	@media(max-width:991px) {
		svg {
			width: 100%;
			height: 100%;
		}
	}

	.PtsCurt {
		font-weight: 600;
		font-size: 20.77px;
		color: #F4C247;
	}

	.PtsCurtvl {
		font-weight: 400;
		font-size: 20px;
		color: #FFFFFF
	}

	.PtsCurtGm {
		font-weight: 600;
		font-size: 30px;
	}

	.PlyrTotAmt {
		font-weight: 400;
		font-size: 29.1568px;
	}

	.PkrGmSldrDv {
		.slick-slide {
			transform: scale(0.85);
			transition: all .5s;

			.sldrCnt {
				padding: 15px;
			}
		}

		.slick-slide.slick-active.slick-current {
			transform: scale(1);
			transition: all .5s;
		}
	}

	.PkrGmSldr2Dv {
		.PkrGmSldr2DvCnt {
			width: 50%;
			display: block;
			margin: auto;
			background: linear-gradient(179.6deg, #000000 -13.88%, #131212 34.97%, #000000 116.83%);
			border-radius: 16px;
			padding: 31px 0px;

			@media(max-width:1199px) {
				width: 100%;
			}

			.slick-slide {
				opacity: 1 !important;

				.PkrGmSldr2DvCntDts {
					.rlmsTx {
						font-weight: 400;
						font-size: 20px;
						color: #FFFFFF;
						text-align: center;
						line-height: 32px;
					}
				}
			}

			.slick-slide.slick-active.slick-current {
				.PkrGmSldr2DvCntDts {
					.rlmsTx {
						font-weight: 600;
						font-size: 28px;
						color: #F0BB45;

					}
				}
			}

		}

		.PkrGmSldr2DvAr.slick-prev:before {
			display: none;
		}

		.PkrGmSldr2DvAr.slick-next:before {
			display: none;
		}

		.PkrGmSldr2DvAr.slick-prev {
			left: 15px;
		}

		.PkrGmSldr2DvAr.slick-next {
			right: 15px;
		}
	}
}

.profBalanceCnt {
	@media(min-width:1200px) and (max-width:1620px) {
		.profBalanceCnt {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}

.videobox-main1 {
	background: url(/assets/images/video-brder-bg.png)no-repeat center;
	background-size: 100% 100%;
	padding: 9px;
	padding: 12px 12px 12px 12px;
	display: -webkit-inline-box;
	display: -moz-inline-box;
	position: relative;

	.VideoBxImg {
		img {
			height: 650px;
			object-fit: cover;

			@media (max-width: 1400px) {
				height: 550px;
			}

			@media (max-width:1200px) {
				height: 450px;
			}

			@media (max-width:767px) {
				height: 350px;
			}

			@media (max-width:480px) {
				height: 250px;
			}
		}

		video {
			height: 500px;
			width: 100%;
			object-fit: cover;

			@media (max-width: 1199px) {
				height: auto;
			}
		}
	}

	.FllVwBtn {
		position: absolute;
		bottom: 45px;
		right: 45px;

		img {
			transition: .8s all ease-in-out;
		}

		&:hover {
			img {
				transform: scale(1.2);
				transition: .4s all ease-in-out;
				cursor: pointer;
			}
		}
	}
}

.login-left {
	img {
		mix-blend-mode: screen;
	}
}

// Game mode page scss 
.GameMdMain {
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(/assets/images/game-md-bg.png);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -1;
	}

	.login-inrcnt {
		min-height: 100vh;
		padding: 0;
		align-items: center;
		display: flex;
	}
}

.GmMdHddSec {
	background-image: url(/assets/images/game-hdd-bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	display: inline-block;
	min-width: 200px;
	min-height: 181px;
	padding: 2.5rem 0;

	h4 {
		font-weight: 600;
		font-size: 31px;
		line-height: 31px;
		color: #FFFFFF;
	}
}

.GmMdHddMain {
	position: relative;

	.GmHdBgLt {
		position: absolute;
		width: 100%;
		bottom: -85px;
		z-index: -1;
		mix-blend-mode: screen;
	}
}

.GameFramSec {
	position: relative;
	padding: 40px 40px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(/assets/images/game-frame.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
}

.Gamebtn {
	font-weight: 500;
	font-size: 30px;
	line-height: 45px;
	color: #FFF;
	background: linear-gradient(181.66deg, #FF9D35 1.41%, rgba(0, 0, 0, 0) 148.07%);
	// backdrop-filter: blur(3.87691px);
	border-radius: 43.0768px;
	border: unset;
	min-width: 267px;
	min-height: 57px;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -1px;
		left: -22px;
		width: 100%;
		height: 100%;
		background-image: url(/assets/images/game-btn-bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		min-width: 311px;
		min-height: 66px;
		transition: .4s all ease-in-out;
	}

	&:hover {
		color: #FFF;

		&::before {
			content: "";
			filter: drop-shadow(2px 4px 20px #edab23);
			transition: .4s all ease-in-out;
		}

		.BtnBgLight {
			color: #FFF;
			transition: .2s all ease;
			-webkit-animation: spin 4s linear infinite;
			-moz-animation: spin 4s linear infinite;
			animation: spin 4s linear infinite;
		}
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: translate(-50%, -50%) rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1.1);
		transform: translate(-50%, -50%) rotate(360deg) scale(1.1);
	}
}

.GmeBtnSec {
	position: relative;
	padding: 50px 0;

	.BtnBgLight {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -2;
		mix-blend-mode: screen;
		width: 100%;
		transition: .4s all ease;
	}
}

// Game mode page scss End
/*Multi Range Slider Style*/
.multiSlider {
	.multi-range-slider {
		border: none;
		box-shadow: none;

		.bar-left,
		.bar-right {
			background: #393939;
			box-shadow: none;
		}

		.bar-inner {
			background: #FF9736;
			box-shadow: none;
		}

		.thumb {
			&:before {
				width: 17px;
				height: 17px;
				background: linear-gradient(140.95deg, #F4C247 28.07%, #EAB144 52%, #D1843D 98.81%, #BD6137 132.1%);
				margin: -5px -12px;
				box-shadow: none;
				border: none;
			}

			.caption * {
				background-color: #FF9736;
				box-shadow: none;
			}
		}
	}
}

/*Multi Range Slider Style End*/
// Announcement page Scss 
.AnounceMainbg.homemain-cnt {
	&::before {
		background-image: url(/assets/images/announcement-bg.png);
	}
}

.AnounceMainbg.homemain-cnt .homepage-inrcnt h1 {
	line-height: 70px;

	@media (max-width: 991px) {
		font-size: 52px;
		line-height: 58px;
	}

	@media (max-width:767px) {
		font-size: 34px;
		line-height: 38px;
	}
}

.Btn200-50 {
	min-width: 200px;
	min-height: 50px;
}

.AnounceMainbg .AnncInrCnt {
	min-height: calc(100vh - 320px);
	padding: 50px 14px;
	@media (max-width:991px) {
		padding: 5px 14px;
	}
}

.AnncInrCnt {
	display: flex;
	justify-content: center;
	align-items: center;

	h1 {
		line-height: 70px;
	}

	p {
		color: #FFF;
		font-size: 18px;
		font-weight: 300;
		line-height: 28px;

		span {
			color: #F1BD46;
			font-weight: 600;
			background: linear-gradient(210deg, #FF8C21 13.4%, #E5F294 86.6%);
			background-clip: border-box;
			background-clip: border-box;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
		}
	}

	h2 {
		span {
			font-size: 36px;
			font-weight: 600;
			line-height: normal;
			background: linear-gradient(210deg, #FF8C21 13.4%, #E5F294 86.6%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;

			@media (max-width: 767px) {
				font-size: 26px;
				line-height: 30px;
			}
		}
	}

	h6 {
		color: #FFF;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
	}
}

.AnncFootr {
	.ftr-social ul {
		grid-template-columns: auto auto auto auto auto auto auto auto auto auto;

		@media (max-width: 600px) {
			grid-template-columns: auto auto auto auto auto;
		}
	}
}

.home-footer .ftr-social ul li a:hover svg path.SvgTxt {
	fill: #F2AA34;
}

.home-footer .language-select .language-grid .SelctDrp {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: transparent;
	padding: 0 30px 0 0;
	border: 0;
	outline: 0;
	margin: 0 0 0 0px;
	background-image: url(/assets/images/down-arrow.png);
	background-position: center right 10px;
	background-repeat: no-repeat;
	background-size: auto;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 27px;
	color: #fff;
	text-decoration: none;
}

.home-footer .language-select .language-grid {
	grid-template-columns: auto;

	.dropdown-menu {
		grid-template-columns: auto;
		background: linear-gradient(180deg, rgba(58, 58, 58, 1) 0%, rgba(0, 0, 0, 1) 100%);
		border: 1.30857px solid #3C3729;
		border-radius: 6.54286px;
		padding: 12px 0;

		.dropdown-item {
			padding: 0.25rem 1rem;
			color: #FFF;

			&:focus,
			&:hover {
				background-color: #141414;
			}
		}
	}
}

.SignCnt {
	p {
		color: #FFF;
		font-size: 25px;
		font-family: Kanit;
		font-weight: 400;
		line-height: 28px;
		margin-bottom: 0;
	}
}

.TextGlow {
	position: relative;
}
.TextGlow::after {
	content: "";
	background: url(../src/assets/images/text-glow-bg.png) no-repeat center bottom 10px;
	background-size: auto;
	position: absolute;
	left:0 ;
	top: 25px;
	width: 100%;
	height: 100%;
	background-size:100%;
	mix-blend-mode: exclusion;
	z-index: 0;
	@media (max-width:991px) {
		top: 0;
		background-size:100% 100%;
	}
  }
  .AnounceMainbg.homemain-cnt .homepage-inrcnt .TextGlow h1{
	position: relative;
	z-index: 9;
  }

.AnncFixedHdr {
	background: rgba(15, 0, 0, 0.6);
	-webkit-backdrop-filter: blur(6.23077px);
	backdrop-filter: blur(6.23077px);
}
.AnncHdr .header-main .header-inrcnt,
.AnncFixedHdr .header-main .header-inrcnt{
		padding: 15px 20px 8px 20px;
	  
}
.AnncHdr{
	position: absolute;
}
// Announcement page Scss End

/* 404 & ComingSoon */
.PnfBg {
	background: url('./assets/images/pnfbg.png') no-repeat;
	background-size: cover;
	background-position: center;

	p {
		color: #FFF;
		leading-trim: both;
		text-edge: cap;
		font-size: 46px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 30px;
		text-align: center;
	}
}